import { canUseDOM } from 'exenv';
import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Sitemeta } from '../../components/core/sitemeta';
import { resolve } from '../../helpers/urls';
import { useNotification } from '../../hooks/useNotification';

const AuthLogoutPage = () => {
  const { t } = useTranslation();
  const { addNotification } = useNotification();

  useEffect(() => {
    if (!canUseDOM) {
      return;
    }

    const homepage = resolve('home');
    addNotification('success', t('Successfully signed out.'));
    navigate(homepage);
  }, []);

  return (<Sitemeta title={t('Authentication')} robots="noindex,follow" />);
};

export default AuthLogoutPage;
